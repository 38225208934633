.App {
  text-align: center;
}
.react-sweet-progress-symbol {
  display: none !important;
  width: 0px !important;
  padding-left: 0px !important;
}
.react-sweet-progress {
  padding-bottom: 12px;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.root {
  display: 'flex';
}
.flex {
  flex: 1;
}
.menuButton {
  margin-left: -12px;
  margin-right: 20px;
}
.card {
  min-width: 275px;
}
.bullet {
  display: 'inline-block';
  margin: '0 2px';
  transform: 'scale(0.8)';
}
.title {
  margin-bottom: 16px;
  font-size: 14px;
}
.pos {
  margin-bottom: 12px;
}
.list {
  width: 100%;
}
.fullList {
  width: 'auto';
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ReactTable .rt-thead:first-of-type .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 12px 5px;
}

.ReactTable {
  border: none !important;
}

.listTitle {
  padding-left: 15px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #ccc;
  line-height: 35px;
}
.pageRoot {
  width: '100%';
}
.tableWrapper {
  overflow-x: 'auto';
  display: 'block';
}

.divBg {
  padding: 25px;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.activeLink,
.activeLink p {
  /* color: #025bb3 */
  /* color: #025bb3bf; */
  /* color: #242530; */
  color: #0070e0;
}

.ct-legend {
  position: relative;
  z-index: 10;
  list-style: none;
  text-align: center;
}
.ct-legend li {
  position: relative;
  padding-left: 23px;
  margin-right: 10px;
  margin-bottom: 3px;
  cursor: pointer;
  display: inline-block;
}
.ct-legend li:before {
  width: 12px;
  height: 12px;
  position: absolute;
  left: 0;
  content: '';
  border: 3px solid transparent;
  border-radius: 2px;
}
.ct-legend li.inactive:before {
  background: transparent;
}
.ct-legend.ct-legend-inside {
  position: absolute;
  top: 0;
  right: 0;
}
.ct-legend.ct-legend-inside li {
  display: block;
  margin: 0;
}
.ct-legend .ct-series-0:before {
  background-color: #453d3f;
  border-color: #453d3f;
}
.ct-legend .ct-series-1:before {
  background-color: #f05b4f;
  border-color: #f05b4f;
}
.ct-legend .ct-series-2:before {
  background-color: #f4c63d;
  border-color: #f4c63d;
}
.ct-legend .ct-series-3:before {
  background-color: #d17905;
  border-color: #d17905;
}
.ct-legend .ct-series-4:before {
  background-color: #ffffff;
  border-color: #ffffff;
}

.MuiSnackbarContent-root {
  background-color: black;
  color: white;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

/* outline: 1px solid slategrey;
border-radius: 30;
height: 0.2em; */
