.test-grid {
  flex-grow: 1;
}

.table-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.table-header {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;
  margin-bottom: 1rem;
}

.customHeaderMenuButton {
  float: left;
  margin: 0 0 0 3px;
}

.customHeaderLabel {
  float: left;
  margin: 0 0 0 3px;
}

.link-renderer a:visited {
  color: inherit;
}

.scroll-list::-webkit-scrollbar {
  width: 5px;
}

.scroll-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll-list::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.chip {
  background-color: #f0f0f0;
}

.chip-dark {
  background-color: #48484a;
  color: white;
}

.chip:hover {
  background-color: #dededf !important ;
}

.changeLogContainer {
  background-color: white;
}

.changeLogContainer-dark {
  background-color: #2b2b2d;
  /* color: white; */
}

.changeLogEditor {
  font-weight: bold;
  border-bottom: 1px black solid;
  margin-bottom: 1em;
}

.changeLogGroup {
  margin-top: 0.5em;
}

.changeLogNested {
  margin-left: 1.5em;
}

.changeLogLabel {
  color: blue;
}

.changeLogValue {
  color: blue;
}

.changeLogBlockLabel {
  color: blue;
  margin-left: 1.5em;
  background-color: #f0f0ff;
}

.changeLogBlockValue {
  color: blue;
  margin-left: 1.5em;
  background-color: #f0f0ff;
}

.ag-cell-not-inline-editing {
  user-select: initial;
  -moz-user-select: text;
  -ms-user-select: text;
  -webkit-user-select: text;
}
